$primary: #99C6B4;

.dashboard-main {
    .ant-card-bordered {
        border: 1px solid $primary !important;
    }
}

// Define some color variables matching Tailwind defaults
$gray-100: #f7fafc;
$gray-200: #edf2f7;
$gray-600: #718096;
$gray-800: #2d3748;
$blue-50: #ebf8ff;
$blue-500: #4299e1;
$blue-600: #3182ce;
$yellow-50: #fffaf0;
$yellow-500: #ecc94b;
$yellow-600: darken($yellow-500, 10%);
$green-50: #f0fff4;
$green-500: #48bb78;
$green-600: #38a169;
$red-500: #f56565;
$red-600: #e53e3e;
$gray-500: #a0aec0;
$purple-500: #9f7aea;
$purple-600: darken($purple-500, 10%);

// TimeTracker.scss

.time-cricle {
    position: relative;
    width: 16rem;
    height: 16rem;
    margin: 0 auto;

    .circular-progress {
        transform: rotate(-90deg);
        width: 16rem;
        height: 16rem;
    }

    .bg-circle {
        color: #DEF7E5;
        stroke: currentColor;
    }

    .pg-circle {
        color: #007144;
        stroke: currentColor;
    }

    .timer-display {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;

        .timer-time {
            font-size: 30px;
            color: black;
            font-weight: bold;
        }

        .timer-pr {
            font-size: 14px;
            color: gray;
            margin-top: 0.5rem;
        }
    }
}