.tracking-table {
    .ant-table {
        scrollbar-color: inherit !important;
    }

    .ant-table-cell {
        padding: 4px;
    }

    h2 {
        font-size: 1.5rem;
    }

    .ant-table-wrapper .ant-table-thead .ant-table-cell {
        padding: 2px 16px !important;
    }

    .ant-table-wrapper .ant-table-tbody .ant-table-cell {
        padding: 11px 16px !important;
    }
}

.ant-table-wrapper .ant-table-ping-right .ant-table-cell-fix-right-first::after {
    box-shadow: none !important;
}